/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import { makeAutoObservable } from 'mobx';
import { getBalance, getBlock, readContract, writeContract } from '@wagmi/core';
import { Address, erc20Abi } from 'viem';
import ky from 'ky';
import Web3 from 'web3';

import { config } from '@/core/wagmiConfig';
import { NETWORK_CHAIN, RESTAKE_SUPPORTED_CHAIN_IDS, VOID_USER, isProduction } from '@/core/config';
import { Network } from '@/shared/types';
import { iterateRpc } from '@/services/web3/getVoidSigner';

import originVaults from './vaults';
import defi from './defi';
import {
  Defi,
  DefiData,
  DefiPointsResponse,
  UserData,
  TokenData,
  UserDefiPointsResponse,
  UsersUserResponse,
  Vault,
  VaultData,
  VaultsResponse,
  DefiUserPoolData,
  PointsEntity,
  PointsReductionReason,
} from './types';
import { collectorAbi } from './collectorAbi';
import { collectorAbiEth } from './collectorAbiEth';
import { DepositEstimatedData, TokenValue } from '../restakeTransactions/types';
import { vaultAbi } from './vaultAbi';
import { wstETHAbi } from './wstETHAbi';

export const zeroAddress = '0x0000000000000000000000000000000000000000';

export const supportedTokens: Record<string, { symbol: string; decimals: number }> = {
  '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84': { symbol: 'STETH', decimals: 18 },
  '0x3F1c547b21f65e10480dE3ad8E19fAAC46C95034': { symbol: 'STETH', decimals: 18 },
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2': { symbol: 'WETH', decimals: 18 },
  '0x94373a4919B3240D86eA41593D5eBa789FEF3848': { symbol: 'WETH', decimals: 18 },
  '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0': { symbol: 'WSTETH', decimals: 18 },
  '0x8d09a4502Cc8Cf1547aD300E066060D043f6982D': { symbol: 'WSTETH', decimals: 18 },
  '0x0000000000000000000000000000000000000000': { symbol: 'ETH', decimals: 18 },
  '0x9D39A5DE30e57443BfF2A8307A4256c8797A3497': { symbol: 'SUSDE', decimals: 18 },
  '0x57e114B691Db790C35207b2e685D4A43181e6061': { symbol: 'ENA', decimals: 18 },
  '0xC937e208aCd2Ea6126A3B7731C7c72f6E9307D1b': { symbol: 'WSTETH', decimals: 18 },
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599': { symbol: 'WBTC', decimals: 8 },
  '0x18084fbA666a33d37592fA2633fD49a74DD93a88': { symbol: 'tBTC', decimals: 18 },
};

const COLLECTOR_API: Partial<Record<Network, string>> = {
  ethereum: '0x240Fbe0790D5B25366BF88EE14AE8Dde72BfE312',
  holesky: '0x28737201847Bd3D02C03bB5855672084dbD205C9',
} as const;

const COLLECTOR_ABI: Partial<Record<Network, typeof collectorAbi>> = {
  ethereum: collectorAbiEth as any,
  holesky: collectorAbi,
} as const;

const pointsDomain = isProduction
  ? 'https://points.mellow.finance'
  : 'https://points-staging.mellow.finance';

class RestakeLrtStore {
  public vaults: Vault[] = [];
  public defi: Defi[] = [];

  public defiData: Record<string, DefiData | undefined> = {};
  // user - vault - data
  public userDataByVault: Record<string, Record<string, UserData>> = {};
  public data: Record<string, VaultData | undefined> = {};
  public tokens: Record<string, TokenData | undefined> = {};
  public dataLoading = false;
  public tokensLoading = false;

  public referralCode: string | undefined;

  constructor() {
    makeAutoObservable(this);
    this.vaults = originVaults as unknown as Vault[];
    this.defi = defi as unknown as Defi[];
  }

  setDefiData = (id: string, value: DefiData) => {
    this.defiData[id] = { ...this.defiData[id], ...value };
  };

  setData = (id: string, value: VaultData) => {
    this.data[id] = { ...this.data[id], ...value };
  };

  setTokens = (id: string, value: TokenData) => {
    this.tokens[id] = { ...this.tokens[id], ...value };
  };

  setReferralCode = (referralCode: string) => {
    this.referralCode = referralCode;
  };

  getUserData = async (vaults: Vault[], userAddress: string) => {
    this.dataLoading = true;

    const [userDefiData, metricData] = (await Promise.all([
      ky(`${pointsDomain}/v1/chain/${NETWORK_CHAIN[vaults[0].network]}/defi/users/${userAddress}`)
        .json()
        .catch(() => []),
      ky(`${pointsDomain}/v1/chain/${NETWORK_CHAIN[vaults[0].network]}/users/${userAddress}`)
        .json()
        .catch(() => []),
    ])) as [UserDefiPointsResponse, UsersUserResponse];

    this.vaults.forEach(vault => {
      const totalPoints: Partial<Record<PointsEntity, number>> = {};

      const defiPools: DefiUserPoolData[] = [];

      for (const data of userDefiData) {
        if (data.vault_address.toLowerCase() !== vault.address.toLowerCase()) {
          continue;
        }

        const pool = this.defi.find(defi => defi.id === data.pool_id);
        if (!pool) {
          continue;
        }

        const defiDataPoints: Partial<Record<PointsEntity, number>> = {
          mellow: Number(data.user_mellow_points || 0) * (pool.boost || 1),
          symbiotic: Number(data.user_symbiotic_points || 0),
        };
        const userPoints: Partial<Record<PointsEntity, number>> = {};

        for (const entity of vault.points) {
          const points = defiDataPoints[entity];
          if (points == null) {
            continue;
          }

          userPoints[entity] = points;

          if (pool.showUserPoints) {
            totalPoints[entity] = (totalPoints[entity] || 0) + points;
          }
        }

        defiPools.push({
          pool: pool.id,
          protocolAddress: data.defi_protocol_address,
          // TODO: position
          position: null,
          positionUSDC: null,
          points: userPoints,
          showUserPoints: pool.showUserPoints,
        });
      }

      const lrtPoints: Partial<Record<PointsEntity, number>> = {};
      let pointsEfficiency: number | undefined;
      let pointsReductionReason: PointsReductionReason | undefined;

      for (const data of metricData) {
        if (data.vault_address.toLowerCase() !== vault.address.toLowerCase()) {
          continue;
        }

        if (data.user_points_efficiency) {
          pointsEfficiency = parseFloat(data.user_points_efficiency);
        }
        if (data.user_points_reduction_reason) {
          pointsReductionReason = data.user_points_reduction_reason;
        }

        const dataPoints: Partial<Record<PointsEntity, number>> = {
          mellow: Number(data.user_referal_points || 0) + Number(data.user_mellow_points || 0),
          symbiotic: Number(data.user_symbiotic_points || 0),
          obol: Number(data.user_obol_points || 0),
          ssv: Number(data.user_ssv_points || 0),
        };

        for (const entity of vault.points) {
          const points = dataPoints[entity];
          if (points == null) {
            continue;
          }
          lrtPoints[entity] = (lrtPoints[entity] || 0) + points;
          totalPoints[entity] = (totalPoints[entity] || 0) + points;
        }
      }

      if (!this.userDataByVault[userAddress]) {
        this.userDataByVault[userAddress] = {};
      }
      this.userDataByVault[userAddress][vault.id] = {
        totalPoints,
        lrtPoints,
        defiPools,
        pointsEfficiency,
        pointsReductionReason,
      };
    });

    this.dataLoading = true;
  };

  getDefiData = async (defi: Defi[]) => {
    if (defi.length === 0) {
      return;
    }

    this.dataLoading = true;

    // Get DeFi points totals
    const allDefiPoints = (await ky(
      `${pointsDomain}/v1/chain/${NETWORK_CHAIN[defi[0].network]}/defi/protocols`,
    )
      .json()
      .catch(() => [])) as DefiPointsResponse;

    await Promise.all(
      defi.map(async ({ id, boost, vaults, pointsAddresses }) => {
        if (!this.defiData[id]) {
          this.defiData[id] = {
            tvl: 0,
          };
        }

        const poolDefiPoints = allDefiPoints.filter(row => row.pool_id === id);

        if (poolDefiPoints.length > 0) {
          const totalMellowPoints = poolDefiPoints.reduce(
            (acc, points) =>
              acc + Number(points.user_mellow_points || 0) * (Number(points.boost) || boost || 1),
            0,
          );
          const totalSymbioticPoints = poolDefiPoints.reduce(
            (acc, points) => acc + Number(points.user_symbiotic_points || 0),
            0,
          );
          if (!this.defiData[id]!.points) {
            this.defiData[id]!.points = {};
          }
          this.defiData[id]!.points!.mellow = totalMellowPoints;
          this.defiData[id]!.points!.symbiotic = totalSymbioticPoints;
        }
      }),
    );

    this.dataLoading = false;
  };

  initTokens = async (tokens: string[], userAddress: string, network: Network) => {
    this.tokensLoading = true;

    const stethWstethPrice = await readContract(config, {
      abi: wstETHAbi,
      address: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
      functionName: 'getStETHByWstETH',
      chainId: 1,
      args: [BigInt(1 * 10 ** 18)],
    });

    const wstethPriceMap: Record<string, number> = {
      STETH: Number(stethWstethPrice) / 10 ** 18,
      WETH: Number(stethWstethPrice) / 10 ** 18,
      WSTETH: 1,
      ETH: Number(stethWstethPrice) / 10 ** 18,
    };

    const tokenBalances = await Promise.all(
      tokens.map(tokenAddress =>
        readContract(config, {
          abi: erc20Abi,
          address: tokenAddress as Address,
          functionName: 'balanceOf',
          args: [userAddress as Address],
          chainId: NETWORK_CHAIN[network],
        }),
      ),
    );

    const ethBalance = await getBalance(config, {
      address: userAddress as Address,
      chainId: NETWORK_CHAIN[network],
    });

    tokens.forEach((address, i) => {
      this.setTokens(address, {
        network,
        userBalance: Number(tokenBalances[i]) / 10 ** supportedTokens[address].decimals,
        userBalanceBN: tokenBalances[i],
        ...supportedTokens[address],
        address,
        usdPrice: 0,
        wstethPrice: wstethPriceMap[supportedTokens[address]?.symbol] || 1,
      });
    });

    this.setTokens(zeroAddress, {
      ...supportedTokens[zeroAddress],
      network,
      userBalanceBN: ethBalance.value,
      address: zeroAddress,
      userBalance: userAddress ? Number(ethBalance.value) / 10 ** 18 : 0,
      usdPrice: 0,
      wstethPrice: wstethPriceMap[supportedTokens[zeroAddress]?.symbol] || 1,
    });
    this.tokensLoading = false;
  };

  getVaults = (network?: Network) => {
    if (network == null || !RESTAKE_SUPPORTED_CHAIN_IDS.has(NETWORK_CHAIN[network])) {
      // eslint-disable-next-line no-param-reassign
      network = 'ethereum';
    }
    return this.vaults.filter(vault => vault.network === network);
  };

  getDefi = (network?: Network) => {
    if (network == null || !RESTAKE_SUPPORTED_CHAIN_IDS.has(NETWORK_CHAIN[network])) {
      // eslint-disable-next-line no-param-reassign
      network = 'ethereum';
    }
    return this.defi.filter(defi => defi.network === network);
  };

  getTokens = (network?: Network) => {
    if (network == null || !RESTAKE_SUPPORTED_CHAIN_IDS.has(NETWORK_CHAIN[network])) {
      // eslint-disable-next-line no-param-reassign
      network = 'ethereum';
    }
    return Object.values(this.tokens).filter(
      (token): token is TokenData => token != null && token.network === network,
    );
  };

  getVaultsData = async (vaults: Vault[], userAddress: string = VOID_USER.holesky) => {
    if (vaults.length === 0) {
      return;
    }

    this.dataLoading = true;

    const result = await readContract(config, {
      abi: vaults[0].network === 'holesky' ? collectorAbi : collectorAbiEth,
      address: COLLECTOR_API[vaults[0].network] as Address,
      functionName: 'collect',
      args: [userAddress as Address, vaults.map(v => v.address as Address)],
      chainId: NETWORK_CHAIN[vaults[0].network],
    });

    const aprs = await this.getVaultsApr(vaults);
    const allPoints = (await ky(
      `${pointsDomain}/v1/chain/${NETWORK_CHAIN[vaults[0].network]}/vaults`,
    )
      .json()
      .catch(() => [])) as VaultsResponse;

    const statusMap = await Promise.all(
      result.map(async ({ vault, withdrawalRequest, shouldCloseWithdrawalRequest }) => {
        const currentVault = this.vaults.find(
          ({ address }) => address.toLowerCase() === vault.toLowerCase(),
        );

        if (!currentVault) {
          return { txHash: '', status: 'none', timestamp: undefined };
        }
        const status = await this.fetchIsWithdrawSuccess(
          currentVault,
          userAddress,
          withdrawalRequest.lpAmount !== BigInt(0),
          shouldCloseWithdrawalRequest,
        );
        return status;
      }),
    );

    await Promise.all(
      result.map(
        async (
          {
            balance,
            vault,
            lpPriceD18,
            totalValueETH,
            totalValueUSDC,
            totalSupply,
            underlyingTokens,
            userBalanceETH,
            userBalanceUSDC,
            depositRatiosX96,
            withdrawalRatiosX96,
            withdrawalRequest: { deadline, lpAmount, minAmounts, timestamp },
            shouldCloseWithdrawalRequest,
            maximalTotalSupplyUSDC,
            maximalTotalSupplyWSTETH,
            totalValueWSTETH,
            totalValueBaseToken,
            maximalTotalSupplyBaseToken,
          },
          i,
        ) => {
          const currentVault = this.vaults.find(
            ({ address }) => address.toLowerCase() === vault.toLowerCase(),
          );
          if (currentVault) {
            const correctedUnderlyingTokens = Array.from(
              new Set([...underlyingTokens, ...(currentVault?.additionalTokens || [])]),
            );

            const currentSymbioticPoints = allPoints.find(
              ({ vault_address }) => currentVault?.address === vault_address,
            )?.vault_symbiotic_points;
            const currentMellowPoints = allPoints.find(
              ({ vault_address }) => currentVault?.address === vault_address,
            )?.vault_mellow_points;

            this.setData(currentVault.id, {
              totalValueETH: Number(totalValueETH) / 10 ** 18,
              totalValueUSDC: Number(totalValueUSDC) / 10 ** 8,
              totalValueBaseToken:
                Number(totalValueBaseToken) /
                10 ** supportedTokens[currentVault.baseToken || underlyingTokens[0]].decimals,
              totalSupply: Number(totalSupply) / 10 ** 18,
              totalValueWSTETH: Number(totalValueWSTETH) / 10 ** 18,
              underlyingTokens: correctedUnderlyingTokens as string[],
              originalUnderlyingTokens: underlyingTokens as string[],
              balance: Number(balance) / 10 ** 18,
              balanceBN: balance,
              userBalanceETH: Number(userBalanceETH) / 10 ** 18,
              userBalanceUSDC: Number(userBalanceUSDC) / 10 ** 8,
              lpPriceD18: Number(lpPriceD18) / 10 ** 8,
              vault,
              apr: aprs[currentVault.id],
              depositRatiosX96: depositRatiosX96 as bigint[],
              withdrawalRatiosX96: withdrawalRatiosX96 as bigint[],
              withdrawalRequest: {
                deadline: Number(deadline),
                lpAmount: Number(lpAmount) / 10 ** 18,
                minAmounts: minAmounts as bigint[],
                timestamp: Number(timestamp) || statusMap[i].timestamp || 0,
                status: statusMap[i].status as any,
                txHash: statusMap[i].txHash,
              },
              shouldCloseWithdrawalRequest,
              mellowPoints: Number(currentMellowPoints) || 0,
              symbioticPoints: Number(currentSymbioticPoints) || 0,
              limitUSDC: Number(maximalTotalSupplyUSDC) / 10 ** 8,
              limitWSTETH: Number(maximalTotalSupplyWSTETH) / 10 ** 18,
              limitBaseToken:
                Number(maximalTotalSupplyBaseToken) /
                10 ** supportedTokens[currentVault.baseToken || underlyingTokens[0]].decimals,
            });

            await this.initTokens(
              correctedUnderlyingTokens as string[],
              userAddress,
              vaults[i].network,
            );
          }
        },
      ),
    );
    this.dataLoading = false;
  };

  fetchDepositWrapperParams = async (
    vault: Vault,
    tokenValue: TokenValue,
  ): Promise<DepositEstimatedData> => {
    const [isDepositPossible, isDepositorWhitelisted, , lpAmount, depositValueUSDC] =
      await readContract(config, {
        abi: COLLECTOR_ABI[vault.network] as typeof collectorAbi,
        address: COLLECTOR_API[vault.network] as Address,
        functionName: 'fetchDepositWrapperParams',
        args: [
          vault.address as Address,
          vault.depositWrapper as Address,
          tokenValue.token.address as Address,
          tokenValue.valueBN,
        ],
        chainId: NETWORK_CHAIN[vault.network],
      });

    return {
      expectedLpAmount: Number(lpAmount) / 10 ** 18,
      expectedLpAmountBN: lpAmount,
      expectedLpAmountUSD: Number(depositValueUSDC) / 10 ** 8,
      isDepositorWhitelisted,
      isDepositPossible,
    };
  };

  fetchDepositAmounts = async (
    vault: Vault,
    tokenValues: TokenValue[],
    userAddress: string = zeroAddress,
  ): Promise<DepositEstimatedData> => {
    const tokensAmounts =
      this.data[vault.id]?.originalUnderlyingTokens.map(tokenAddress => {
        const currentTokenValue = tokenValues.find(
          ({ token: { address } }) => address === tokenAddress,
        );
        return currentTokenValue?.valueBN || BigInt(0);
      }) || [];

    const { expectedLpAmount, expectedLpAmountUSDC, isDepositorWhitelisted, isDepositPossible } =
      await readContract(config, {
        abi: COLLECTOR_ABI[vault.network] as typeof collectorAbi,
        address: COLLECTOR_API[vault.network] as Address,
        functionName: 'fetchDepositAmounts',
        args: [tokensAmounts, vault.address as Address, userAddress as Address],
        chainId: NETWORK_CHAIN[vault.network],
      });

    return {
      expectedLpAmount: Number(expectedLpAmount) / 10 ** 18,
      expectedLpAmountBN: expectedLpAmount,
      expectedLpAmountUSD: Number(expectedLpAmountUSDC) / 10 ** 8,
      isDepositorWhitelisted,
      isDepositPossible,
    };
  };

  subscribeToEvents = (userAddress: string, vault: Vault) => {
    const data = this.data[vault.id];

    const interval = setInterval(() => {
      if (data) {
        this.getVaultsData([vault], userAddress);
      }
    }, 1000 * 60);

    return () => clearInterval(interval);
  };

  fetchWithdrawalAmounts = async (vault: Vault, lpAmount: bigint) => {
    // добавить slippage 0.0001
    const [expectedAmounts, expectedAmountsUSDC] = await readContract(config, {
      abi: COLLECTOR_ABI[vault.network] as typeof collectorAbi,
      address: COLLECTOR_API[vault.network] as Address,
      functionName: 'fetchWithdrawalAmounts',
      args: [lpAmount, vault.address as Address],
      chainId: NETWORK_CHAIN[vault.network],
    });

    return {
      expectedAmounts: expectedAmounts.map((amount, i) => {
        const currentTokenAddress = this.data[vault.id]?.originalUnderlyingTokens[i];
        const currentToken = this.tokens[currentTokenAddress!];

        return {
          valueUSDC: String(Number(expectedAmountsUSDC[i]) / 10 ** 8),
          value: String(Number(amount) / 10 ** currentToken!.decimals),
          valueBN: amount,
          token: currentToken!,
          throughWrapper: false,
        };
      }),
    };
  };

  cancelWithdraw = async (vault: Vault) => {
    const tx = await writeContract(config, {
      abi: vaultAbi,
      address: vault.address as Address,
      functionName: 'cancelWithdrawalRequest',
      chainId: NETWORK_CHAIN[vault.network],
      args: [],
    });

    return tx;
  };

  fetchIsWithdrawSuccess = async (
    vault: Vault,
    userAddress: string,
    hasPendingRequest: boolean,
    shouldCloseWithdrawalRequest: boolean,
  ) => {
    let hasEvent = true;

    const logs = await iterateRpc(vault.network, async url => {
      const voidProvider = new Web3.providers.HttpProvider(url);
      const w3 = new Web3(voidProvider);

      const proxyContract = new w3.eth.Contract(vaultAbi as any, vault.address);
      const toBlock = await w3.eth.getBlockNumber();

      const [withdrawalsProcessedEvents, withdrawalRequestCanceledEvents] = await Promise.all([
        proxyContract.getPastEvents('WithdrawalsProcessed', {
          fromBlock: toBlock - 50000,
          toBlock,
        }),
        proxyContract.getPastEvents('WithdrawalRequestCanceled', {
          fromBlock: toBlock - 50000,
          toBlock,
        }),
      ]);

      // same `users` interface to facilitate code processing below
      // eslint-disable-next-line no-restricted-syntax
      for (const event of withdrawalRequestCanceledEvents) {
        event.returnValues.users = [event.returnValues.user];
      }

      // merge events
      const events = [...withdrawalsProcessedEvents, ...withdrawalRequestCanceledEvents].sort(
        (a, b) => {
          if (a.blockNumber === b.blockNumber) {
            if (a.transactionIndex === b.transactionIndex) {
              return a.logIndex - b.logIndex;
            }
            return a.transactionIndex - b.transactionIndex;
          }
          return a.blockNumber - b.blockNumber;
        },
      );

      return events;
    });

    if (logs?.length === 0) {
      hasEvent = false;
    }

    // Get last user event
    const eventIndex = logs?.findLastIndex(
      event => event?.returnValues?.users?.indexOf(userAddress as Address) !== -1,
    );

    const userEvent = logs?.[eventIndex];
    const userIndex = userEvent?.returnValues?.users?.indexOf(userAddress as Address);

    if (userIndex === undefined || userIndex === -1) {
      hasEvent = false;
    }

    const isCanceled = userEvent?.event === 'WithdrawalRequestCanceled';
    const isSuccess =
      userEvent?.event === 'WithdrawalsProcessed' && userEvent?.returnValues?.statuses?.[userIndex];
    const txHash = userEvent?.transactionHash;

    if (!hasPendingRequest && isCanceled) {
      const res = await getBlock(config, { blockHash: userEvent.blockHash as Address });
      return { txHash, status: 'canceled', timestamp: Number(res.timestamp) };
    }
    if (hasPendingRequest && shouldCloseWithdrawalRequest === false) {
      return { txHash: '', status: 'pending' };
    }
    if (!hasPendingRequest && isSuccess) {
      const res = await getBlock(config, { blockHash: userEvent.blockHash as Address });
      return { txHash, status: 'success', timestamp: Number(res.timestamp) };
    }
    if (hasPendingRequest) {
      return { txHash: '', status: 'failed-cancel' };
    }
    if (hasEvent) {
      return { txHash: '', status: 'failed' };
    }

    return { txHash: '', status: 'none' };
  };

  getVaultsApr = async (vaults: Vault[]) => {
    const vaultAPRs: { [id: string]: number | string } = {};

    const stethAPRResponse = await ky('https://eth-api.lido.fi/v1/protocol/steth/apr/sma')
      .json()
      .catch(() => null);
    const stethAPR = ((stethAPRResponse as any)?.data?.smaApr as number) || 0;

    const stethAPRs = [
      'ethereum-dvsteth',
      'ethereum-steaklrt',
      'ethereum-re7lrt',
      'ethereum-amphreth',
      'ethereum-rsteth',
      'ethereum-ifseth',
      'ethereum-lugaeth',
      'ethereum-coeth',
      'ethereum-pzeth',
      'ethereum-roeth',
      'ethereum-urlrt',
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const vault of vaults) {
      if (vault.customApr) {
        vaultAPRs[vault.id] = vault.customApr || 0;
      } else if (stethAPRs.includes(vault.id)) {
        vaultAPRs[vault.id] = stethAPR;
      } else {
        vaultAPRs[vault.id] = '—';
      }
    }

    return vaultAPRs;
  };
}

export const toArrayWithAddress = <T>(obj: Record<string, T>) =>
  Object.entries(obj).map(([key, values]) => ({ address: key, ...values }));

export const restakeLrtStore = new RestakeLrtStore();

if (!isProduction) {
  (window as any).restakeLrtStore = restakeLrtStore;
}
